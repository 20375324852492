import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { footer } from './footer.module.scss';
import { keinoBusinessCard, johoBusinessCard } from '../../config/business-cards';
import { breakpoints } from '../../config/breakpoints';
import useWindowWidth from '../../hooks/use-window-width';

import BusinessCard from './business-card';

interface IFooterTopProps {
    className?: string;
}

const FooterTop: React.FC<IFooterTopProps> = ({ className = '' }) => {
    const { language } = useI18next();
    const windowWidth = useWindowWidth() || breakpoints.phone;
    const isCentered = windowWidth < breakpoints.phone;
    const businessCard = {
        ...keinoBusinessCard,
        ...johoBusinessCard,
        translations: {
            [language]: {
                ...keinoBusinessCard.translations[language],
                ...johoBusinessCard.translations[language],
            }
        }
    }
    return (
        <footer className={`${footer} ${className}`}>
            <BusinessCard
                businessCard={businessCard}
                isCentered={isCentered}
                logoFileType="svg"
            />
        </footer>
    );
};

export default FooterTop;
