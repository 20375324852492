import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container } from './blank-card.module.scss';

interface IBlankCardProps {
    className?: string;
}

const BlankCard: React.FC<IBlankCardProps> = ({ className = '' }) => {
    const { t } = useI18next();
    return (
        <div className={`${container} ${className}`}>
            <p>{t('tile.placeholder')}</p>
        </div>
    );
};

export default BlankCard;
