import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Textfit } from 'react-textfit';

import { container } from './slogan-card.module.scss';
import { ISlogan } from '../../models/slogan.model';
import { useLocation } from '@reach/router';

interface ISloganCardProps {
    className?: string;
    slogan: ISlogan;
}

const SloganCard: React.FC<ISloganCardProps> = ({ className = '', slogan }) => {
    const { contentWithoutOrphans } = slogan;
    const { search } = useLocation();
    const [isReady, setIsReady] = useState(false);
    const [forceRenderId, setForceRenderId] = useState(0);

    // 🚨 HACK ALERT! 🚨
    // To fit font size properly by Textfit useLayoutEffect with setTimeout was needed!
    // If anyone has a different idea - feel free to refactor
    useLayoutEffect(() => {
        const timeout = setTimeout(() => {
            setIsReady(true);
        }, 0);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setForceRenderId(prevState => prevState + 1);
        }, 500);
        return () => clearTimeout(timeout);
    }, [search]);

    if (!isReady) return null;

    return (
        <Textfit key={forceRenderId} mode="multi" max={200} className={`${container} ${className}`}>
            {contentWithoutOrphans}
        </Textfit>
    );
};

export default SloganCard;
