// extracted by mini-css-extract-plugin
export var background = "intro-card-module--background--vaNMU";
export var canBlur = "intro-card-module--can-blur--gJVxp";
export var container = "intro-card-module--container--nXtAU";
export var content = "intro-card-module--content--6oiaJ";
export var dimLayer = "intro-card-module--dim-layer--xKC7U";
export var icon = "intro-card-module--icon--8d+14";
export var iconBox = "intro-card-module--icon-box--yh8Y2";
export var imageBox = "intro-card-module--image-box--o3i4L";
export var introLabel = "intro-card-module--intro-label--T8GmH";
export var ratio = "intro-card-module--ratio--6Y5oC";