import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { footer, bottom, privacy, cookies, created, bottomContent } from './footer.module.scss';

import Markdown from '../hoc/markdown';

interface IFooterBottomProps {
    className?: string;
}

const FooterBottom: React.FC<IFooterBottomProps> = ({ className = '' }) => {
    const { t } = useI18next();
    return (
        <footer className={`${footer} ${bottom} ${className}`}>
            <div className={bottomContent}>
                <Markdown className={privacy}>{t('footer.privacy')}</Markdown>
                <Markdown className={cookies}>{t('footer.cookies')}</Markdown>
                <Markdown>{t('footer.copyright')}</Markdown>
                <Markdown className={created}>{t('footer.created')}</Markdown>
            </div>
        </footer>
    );
};

export default FooterBottom;
