import React, { useRef } from 'react';
import { motion, Variant } from 'framer-motion';

import { container, motionContainer, hidden, noSizeTransition } from './grid-item.module.scss';
import { PAGE_CHANGE_DURATION } from '../../config/consts';
import { IGridItem, TGridItemType } from '../../models/grid-item.model';
import { useIntroContext } from '../../contexts/intro-context';
import { getRandomInteger } from '../../utils/get-random-integer';

interface IGridItemProps {
    className?: string;
    gridItem: IGridItem | undefined;
}

type TAnimationVariantsKeys = 'start' | 'done' | 'finish' | 'introStart';
type TTileAnimationVariants = Record<TAnimationVariantsKeys, Variant>;

const variantsKeyMap: Record<TAnimationVariantsKeys, string> = {
    start: 'start',
    done: 'done',
    introStart: 'introStart',
    finish: 'finish',
};

const GridItem: React.FC<IGridItemProps> = ({ className = '', gridItem, children }) => {
    const { style, type } = gridItem || {};
    const { isPlaying } = useIntroContext();
    const variants = useRef(getAnimationVariants(type));
    return (
        <div
            className={`
                ${container} 
                ${className}
                ${style ? '' : hidden}
                ${type === 'slogan' ? noSizeTransition : ''}
            `}
            style={style}
        >
            <motion.div
                className={motionContainer}
                variants={variants.current}
                initial={variantsKeyMap.start}
                animate={isPlaying ? variantsKeyMap.introStart : variantsKeyMap.done}
                exit={variantsKeyMap.finish}
            >
                {children}
            </motion.div>
        </div>
    );
};

function getAnimationVariants(type?: TGridItemType): TTileAnimationVariants {
    const defaultStart: Variant = {
        opacity: 0,
        x: getRandomInteger(-1000, 1000) * -1,
        y: getRandomInteger(-1000, 1000),
        scale: 0.1,
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    };

    const defaultDone: Variant = {
        opacity: 1,
        x: 0,
        y: 0,
        scale: 1,
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    };

    const defaultFinish: Variant = {
        opacity: 0,
        x: '80vw',
        transition: {
            duration: PAGE_CHANGE_DURATION,
        },
    };

    const defaultVariants: TTileAnimationVariants = {
        start: defaultStart,
        done: defaultDone,
        finish: defaultFinish,
        introStart: defaultStart,
    };

    const sloganStartVariant: Variant = {
        opacity: 0,
        y: '50%',
    };

    const sloganDoneVariant: Variant = {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.5,
        },
    };

    const introIntroStart: Variant = {
        x: 0,
        y: 0,
        scale: 1,
        opacity: 0,
        transition: {
            delay: 0.2,
        },
    };

    const sloganVariants: TTileAnimationVariants = {
        start: sloganStartVariant,
        done: sloganDoneVariant,
        finish: defaultFinish,
        introStart: sloganStartVariant,
    };

    const introVariants: TTileAnimationVariants = {
        start: defaultStart,
        done: defaultDone,
        finish: defaultFinish,
        introStart: introIntroStart,
    };

    const animations: Record<string, TTileAnimationVariants> = {
        default: defaultVariants,
        slogan: sloganVariants,
        intro: introVariants,
    };

    return (type && animations[type]) || animations.default;
}

export default GridItem;
