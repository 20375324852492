import { IGridTemplate } from '../models/grid-template.model';

export const gridTemplates: IGridTemplate[] = [
    {
        gridId: 1,
        hasMainItem: true,
        orientation: 'horizontal',
        units: 3,
        breakpoints: {
            1025: {
                orientation: 'vertical',
                units: 3,
            },
            500: {
                orientation: 'vertical',
                units: 2,
            },
        },
        positions: [
            {
                column: 1,
                row: 1,
                widthUnits: 2,
                heightUnits: 2,
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 1,
                        widthUnits: 2,
                        heightUnits: 2,
                    },
                    500: {
                        column: 1,
                        row: 1,
                        widthUnits: 2,
                        heightUnits: 2,
                    },
                },
                allowed: ['post'],
            },
            {
                column: 1,
                row: 3,
                widthUnits: 1,
                heightUnits: 1,
                breakpoints: {
                    1025: {
                        column: 3,
                        row: 1,
                        widthUnits: 1,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 3,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
                allowed: ['post', 'intro', 'contact', 'blank'],
            },
            {
                column: 3,
                row: 1,
                widthUnits: 2,
                heightUnits: 1,
                breakpoints: {
                    1025: {
                        column: 3,
                        row: 2,
                        widthUnits: 1,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 4,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
                allowed: ['post', 'blank'],
            },
            {
                column: 3,
                row: 2,
                widthUnits: 2,
                heightUnits: 1,
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 3,
                        widthUnits: 1,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 5,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
                allowed: ['post', 'blank'],
            },
            {
                column: 2,
                row: 3,
                widthUnits: 3,
                heightUnits: 1,
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 4,
                        widthUnits: 3,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 6,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
                allowed: ['slogan'],
            },
            {
                column: 5,
                row: 1,
                widthUnits: 1,
                heightUnits: 2,
                breakpoints: {
                    1025: {
                        column: 2,
                        row: 3,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 7,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
                allowed: ['post', 'blank'],
            },
            {
                column: 5,
                row: 3,
                widthUnits: 2,
                heightUnits: 1,
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 5,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 8,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
                allowed: ['post', 'blank'],
            },
            {
                column: 6,
                row: 1,
                widthUnits: 1,
                heightUnits: 1,
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 6,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 9,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
                allowed: ['post', 'contact', 'blank'],
            },
            {
                column: 6,
                row: 2,
                widthUnits: 1,
                heightUnits: 1,
                breakpoints: {
                    1025: {
                        column: 3,
                        row: 5,
                        widthUnits: 1,
                        heightUnits: 2,
                    },
                    500: {
                        column: 1,
                        row: 10,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
                allowed: ['post', 'contact', 'blank'],
            },
        ],
    },
    {
        gridId: 2,
        hasMainItem: true,
        orientation: 'horizontal',
        units: 3,
        breakpoints: {
            1025: {
                orientation: 'vertical',
                units: 3,
            },
            500: {
                orientation: 'vertical',
                units: 2,
            },
        },
        positions: [
            {
                column: 1,
                row: 2,
                widthUnits: 2,
                heightUnits: 2,
                allowed: ['post'],
                breakpoints: {
                    1025: {
                        column: 2,
                        row: 1,
                        widthUnits: 2,
                        heightUnits: 2,
                    },
                    500: {
                        column: 1,
                        row: 1,
                        widthUnits: 2,
                        heightUnits: 2,
                    },
                },
            },
            {
                column: 1,
                row: 1,
                widthUnits: 1,
                heightUnits: 1,
                allowed: ['post', 'blank', 'intro'],
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 1,
                        widthUnits: 1,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 3,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 2,
                row: 1,
                widthUnits: 2,
                heightUnits: 1,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 2,
                        widthUnits: 1,
                        heightUnits: 2,
                    },
                    500: {
                        column: 1,
                        row: 4,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 3,
                row: 2,
                widthUnits: 3,
                heightUnits: 1,
                allowed: ['slogan'],
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 4,
                        widthUnits: 3,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 5,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 3,
                row: 3,
                widthUnits: 1,
                heightUnits: 1,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 2,
                        row: 3,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 6,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 4,
                row: 1,
                widthUnits: 2,
                heightUnits: 1,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 5,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 7,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 4,
                row: 3,
                widthUnits: 2,
                heightUnits: 1,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 3,
                        row: 5,
                        widthUnits: 1,
                        heightUnits: 2,
                    },
                    500: {
                        column: 1,
                        row: 8,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 6,
                row: 1,
                widthUnits: 1,
                heightUnits: 1,
                allowed: ['post', 'contact', 'blank'],
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 6,
                        widthUnits: 1,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 9,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 6,
                row: 2,
                widthUnits: 1,
                heightUnits: 2,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 2,
                        row: 6,
                        widthUnits: 1,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 10,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
        ],
    },
    {
        gridId: 3,
        hasMainItem: false,
        orientation: 'horizontal',
        units: 3,
        breakpoints: {
            1025: {
                orientation: 'vertical',
                units: 3,
            },
            500: {
                orientation: 'vertical',
                units: 2,
            },
        },
        positions: [
            {
                column: 1,
                row: 1,
                widthUnits: 1,
                heightUnits: 2,
                allowed: ['post'],
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 1,
                        widthUnits: 1,
                        heightUnits: 2,
                    },
                    500: {
                        column: 1,
                        row: 1,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 1,
                row: 3,
                widthUnits: 2,
                heightUnits: 1,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 2,
                        row: 1,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 2,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 2,
                row: 1,
                widthUnits: 3,
                heightUnits: 2,
                allowed: ['slogan'],
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 3,
                        widthUnits: 3,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 3,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 3,
                row: 3,
                widthUnits: 2,
                heightUnits: 1,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 2,
                        row: 2,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 4,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 5,
                row: 1,
                widthUnits: 1,
                heightUnits: 1,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 4,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 5,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 5,
                row: 2,
                widthUnits: 1,
                heightUnits: 2,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 3,
                        row: 4,
                        widthUnits: 1,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 6,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 6,
                row: 1,
                widthUnits: 1,
                heightUnits: 2,
                allowed: ['post', 'blank'],
                breakpoints: {
                    1025: {
                        column: 2,
                        row: 5,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 7,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
            {
                column: 6,
                row: 3,
                widthUnits: 1,
                heightUnits: 1,
                allowed: ['post', 'contact', 'blank'],
                breakpoints: {
                    1025: {
                        column: 1,
                        row: 5,
                        widthUnits: 1,
                        heightUnits: 1,
                    },
                    500: {
                        column: 1,
                        row: 8,
                        widthUnits: 2,
                        heightUnits: 1,
                    },
                },
            },
        ],
    },
];
