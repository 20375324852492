import { IGridTemplate } from '../models/grid-template.model';

export const footerGridTemplate: IGridTemplate = {
    gridId: 101,
    orientation: 'horizontal',
    units: 3,
    hasMainItem: false,
    breakpoints: {
        1025: {
            orientation: 'vertical',
            units: 3,
        },
        500: {
            orientation: 'vertical',
            units: 2,
        },
    },
    positions: [
        {
            column: 1,
            row: 1,
            widthUnits: 1,
            heightUnits: 2,
            allowed: ['footerTop'],
            breakpoints: {
                1025: {
                    column: 1,
                    row: 1,
                    widthUnits: 1,
                    heightUnits: 1,
                },
                500: {
                    column: 1,
                    row: 1,
                    widthUnits: 2,
                    heightUnits: 2,
                },
            },
        },
        {
            column: 1,
            row: 3,
            widthUnits: 1,
            heightUnits: 1,
            allowed: ['footerBottom'],
            breakpoints: {
                1025: {
                    column: 2,
                    row: 1,
                    widthUnits: 2,
                    heightUnits: 1,
                },
                500: {
                    column: 1,
                    row: 3,
                    widthUnits: 2,
                    heightUnits: 1,
                },
            },
        },
    ],
};
